<p-button
  (click)="onAddClick()"
  label="{{ 'Common.AddNew' | translate }}"
  icon="pi pi-plus"
  [outlined]="true"></p-button>
<p-dialog
  header="{{ 'Contracts.AddContract' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <app-add-contract-form
    (submitContract)="onSubmit()"
    (cancelContract)="onCancelClick()"></app-add-contract-form>
</p-dialog>
