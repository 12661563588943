/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanApiResponse } from '../models/boolean-api-response';
import { checkAdminAccessAsync } from '../fn/access/check-admin-access-async';
import { CheckAdminAccessAsync$Params } from '../fn/access/check-admin-access-async';
import { checkAdminAccessAsync$Plain } from '../fn/access/check-admin-access-async-plain';
import { CheckAdminAccessAsync$Plain$Params } from '../fn/access/check-admin-access-async-plain';
import { checkAdminOrMasterAccessAsync } from '../fn/access/check-admin-or-master-access-async';
import { CheckAdminOrMasterAccessAsync$Params } from '../fn/access/check-admin-or-master-access-async';
import { checkAdminOrMasterAccessAsync$Plain } from '../fn/access/check-admin-or-master-access-async-plain';
import { CheckAdminOrMasterAccessAsync$Plain$Params } from '../fn/access/check-admin-or-master-access-async-plain';
import { checkAnyRoleAccessAsync } from '../fn/access/check-any-role-access-async';
import { CheckAnyRoleAccessAsync$Params } from '../fn/access/check-any-role-access-async';
import { checkAnyRoleAccessAsync$Plain } from '../fn/access/check-any-role-access-async-plain';
import { CheckAnyRoleAccessAsync$Plain$Params } from '../fn/access/check-any-role-access-async-plain';
import { checkApiKeyAccessAsync } from '../fn/access/check-api-key-access-async';
import { CheckApiKeyAccessAsync$Params } from '../fn/access/check-api-key-access-async';
import { checkApiKeyAccessAsync$Plain } from '../fn/access/check-api-key-access-async-plain';
import { CheckApiKeyAccessAsync$Plain$Params } from '../fn/access/check-api-key-access-async-plain';
import { checkFinancialAdvisorAccessAsync } from '../fn/access/check-financial-advisor-access-async';
import { CheckFinancialAdvisorAccessAsync$Params } from '../fn/access/check-financial-advisor-access-async';
import { checkFinancialAdvisorAccessAsync$Plain } from '../fn/access/check-financial-advisor-access-async-plain';
import { CheckFinancialAdvisorAccessAsync$Plain$Params } from '../fn/access/check-financial-advisor-access-async-plain';
import { checkMasterAccessAsync } from '../fn/access/check-master-access-async';
import { CheckMasterAccessAsync$Params } from '../fn/access/check-master-access-async';
import { checkMasterAccessAsync$Plain } from '../fn/access/check-master-access-async-plain';
import { CheckMasterAccessAsync$Plain$Params } from '../fn/access/check-master-access-async-plain';

@Injectable({ providedIn: 'root' })
export class AccessService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `checkAnyRoleAccessAsync()` */
  static readonly CheckAnyRoleAccessAsyncPath = '/api/access';

  /**
   * Check any role Authorization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAnyRoleAccessAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAnyRoleAccessAsync$Plain$Response(params?: CheckAnyRoleAccessAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkAnyRoleAccessAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check any role Authorization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAnyRoleAccessAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAnyRoleAccessAsync$Plain(params?: CheckAnyRoleAccessAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkAnyRoleAccessAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check any role Authorization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAnyRoleAccessAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAnyRoleAccessAsync$Response(params?: CheckAnyRoleAccessAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkAnyRoleAccessAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check any role Authorization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAnyRoleAccessAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAnyRoleAccessAsync(params?: CheckAnyRoleAccessAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkAnyRoleAccessAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `checkApiKeyAccessAsync()` */
  static readonly CheckApiKeyAccessAsyncPath = '/api/access/api-key';

  /**
   * Check ApiKey Authorization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkApiKeyAccessAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkApiKeyAccessAsync$Plain$Response(params?: CheckApiKeyAccessAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkApiKeyAccessAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check ApiKey Authorization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkApiKeyAccessAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkApiKeyAccessAsync$Plain(params?: CheckApiKeyAccessAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkApiKeyAccessAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check ApiKey Authorization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkApiKeyAccessAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkApiKeyAccessAsync$Response(params?: CheckApiKeyAccessAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkApiKeyAccessAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check ApiKey Authorization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkApiKeyAccessAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkApiKeyAccessAsync(params?: CheckApiKeyAccessAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkApiKeyAccessAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `checkAdminAccessAsync()` */
  static readonly CheckAdminAccessAsyncPath = '/api/access/admin';

  /**
   * Check Authorization for Admin role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAdminAccessAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminAccessAsync$Plain$Response(params?: CheckAdminAccessAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkAdminAccessAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization for Admin role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAdminAccessAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminAccessAsync$Plain(params?: CheckAdminAccessAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkAdminAccessAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check Authorization for Admin role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAdminAccessAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminAccessAsync$Response(params?: CheckAdminAccessAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkAdminAccessAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization for Admin role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAdminAccessAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminAccessAsync(params?: CheckAdminAccessAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkAdminAccessAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `checkMasterAccessAsync()` */
  static readonly CheckMasterAccessAsyncPath = '/api/access/master';

  /**
   * Check Authorization for Master role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkMasterAccessAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMasterAccessAsync$Plain$Response(params?: CheckMasterAccessAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkMasterAccessAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization for Master role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkMasterAccessAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMasterAccessAsync$Plain(params?: CheckMasterAccessAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkMasterAccessAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check Authorization for Master role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkMasterAccessAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMasterAccessAsync$Response(params?: CheckMasterAccessAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkMasterAccessAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization for Master role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkMasterAccessAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMasterAccessAsync(params?: CheckMasterAccessAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkMasterAccessAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `checkFinancialAdvisorAccessAsync()` */
  static readonly CheckFinancialAdvisorAccessAsyncPath = '/api/access/financial-advisor';

  /**
   * Check Authorization for Financial Advisor role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkFinancialAdvisorAccessAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkFinancialAdvisorAccessAsync$Plain$Response(params?: CheckFinancialAdvisorAccessAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkFinancialAdvisorAccessAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization for Financial Advisor role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkFinancialAdvisorAccessAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkFinancialAdvisorAccessAsync$Plain(params?: CheckFinancialAdvisorAccessAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkFinancialAdvisorAccessAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check Authorization for Financial Advisor role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkFinancialAdvisorAccessAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkFinancialAdvisorAccessAsync$Response(params?: CheckFinancialAdvisorAccessAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkFinancialAdvisorAccessAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization for Financial Advisor role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkFinancialAdvisorAccessAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkFinancialAdvisorAccessAsync(params?: CheckFinancialAdvisorAccessAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkFinancialAdvisorAccessAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `checkAdminOrMasterAccessAsync()` */
  static readonly CheckAdminOrMasterAccessAsyncPath = '/api/access/admin-master';

  /**
   * Check Authorization for Admon or Master role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAdminOrMasterAccessAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminOrMasterAccessAsync$Plain$Response(params?: CheckAdminOrMasterAccessAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkAdminOrMasterAccessAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization for Admon or Master role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAdminOrMasterAccessAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminOrMasterAccessAsync$Plain(params?: CheckAdminOrMasterAccessAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkAdminOrMasterAccessAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check Authorization for Admon or Master role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAdminOrMasterAccessAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminOrMasterAccessAsync$Response(params?: CheckAdminOrMasterAccessAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkAdminOrMasterAccessAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization for Admon or Master role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAdminOrMasterAccessAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminOrMasterAccessAsync(params?: CheckAdminOrMasterAccessAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkAdminOrMasterAccessAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

}
