/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AlertsService, ProfileService } from '@app/core/services';
import { firstValueFrom } from 'rxjs';
import { ErrorService } from '../services/error.service';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective {
  @Input() set appHasRole(requiredRoles: number[]) {
    this.updateView(requiredRoles);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private profileService: ProfileService,
    private alertsService: AlertsService,
    private errorService: ErrorService
  ) {}

  private async updateView(requiredRoles: number[]) {
    this.viewContainer.clear();

    if (!this.profileService.roles || this.profileService.roles.length === 0) {
      await this.loadUserRoles();
    }

    if (this.profileService.hasRole(requiredRoles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      const element = this.viewContainer.element?.nativeElement;
      if (element) {
        element.style.display = 'none';
      }
    }
  }

  private async loadUserRoles(): Promise<void> {
    try {
      await firstValueFrom(this.profileService.userLoaded);
      if (
        !this.profileService.roles ||
        this.profileService.roles.length === 0
      ) {
        this.alertsService.addWarning(
          'Alerts.UserRoleError',
          'Alerts.UserRoleErrorDescription',
          true
        );
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
