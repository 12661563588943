import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TotalInvestmentsDto,
  TotalInvestmentsDtoApiResponse
} from '@app/data/amn-api/models';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { ReportService } from '@amn-api/services/report.service';
import { AlertsService, RolesService } from '@app/core/services';
import { first } from 'rxjs';
import { TotalInvestmentsComponent } from '@app/shared/components/total-investments/total-investments.component';
import { ErrorService } from '@app/core/services/error.service';
import { DashboardChartDailyGainsComponent } from '../../components/dashboard-chart-daily-gains/dashboard-chart-daily-gains.component';
import { ROLE_MASTER, ROLE_ADMIN } from '@data/models';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  standalone: true,
  selector: 'app-dashboard',
  imports: [
    TranslateModule,
    CommonModule,
    PrimeNgModule,
    TotalInvestmentsComponent,
    DashboardChartDailyGainsComponent,
    SharedModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  ROLE_MASTER = ROLE_MASTER;
  ROLE_ADMIN = ROLE_ADMIN;
  private reportService = inject(ReportService);
  private alertsService = inject(AlertsService);
  private errorService = inject(ErrorService);
  private rolesService = inject(RolesService);

  customersTotals: TotalInvestmentsDto | undefined;
  top: number = 10;
  skip: number = 0;
  searchCriteriaCustomer: string = '';
  sortBy: string = '';
  sortDesc: boolean = false;
  canViewDailyGains: boolean = false;

  ngOnInit(): void {
    this.reportService
      .searchCustomersInvestments({
        SearchText: this.searchCriteriaCustomer,
        Top: this.top,
        Skip: this.skip,
        SortBy: this.sortBy,
        SortDescending: this.sortDesc
      })
      .pipe(first())
      .subscribe({
        next: (value: TotalInvestmentsDtoApiResponse) => {
          if (value.data && value.data.customerInvestments) {
            this.customersTotals = value.data;
          } else {
            this.alertsService.addWarning(
              'Alerts.CustomersNotFound',
              'Alerts.CustomersNotFoundDesc',
              true
            );
          }
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }
}
