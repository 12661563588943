import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeNgModule } from './primeng/primeng.module';
import { HasRoleDirective } from '@app/core/directives/has-role.directive';

import {
  AlertsComponent,
  AmnDropdownComponent,
  AmnFilesUploadComponent,
  AmnSingleFileUploadComponent,
  AmnInputComponent,
  AmnInputTextareaComponent,
  AmnInputDateComponent,
  AmnInputMaskComponent,
  AmnInputPhoneComponent,
  AmnInputPercentComponent,
  AmnInputMoneyComponent,
  AmnInputCheckboxComponent,
  AvatarComponent,
  SwitchLanguageComponent
} from './components';

import { PhonePipe } from './pipes';

const modules = [
  CommonModule,
  PrimeNgModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  RouterModule,
  TranslateModule
];

const pipes = [PhonePipe];

const components = [
  AlertsComponent,
  AmnDropdownComponent,
  AmnInputComponent,
  AmnInputTextareaComponent,
  AmnInputDateComponent,
  AmnInputMaskComponent,
  AmnInputPhoneComponent,
  AmnInputPercentComponent,
  AmnInputMoneyComponent,
  AmnInputCheckboxComponent
];

const standaloneComponents = [
  AmnFilesUploadComponent,
  AmnSingleFileUploadComponent,
  AvatarComponent,
  SwitchLanguageComponent
];

const directives = [HasRoleDirective];

/*
const services = [
]
*/

@NgModule({
  imports: [...modules, ...standaloneComponents],
  declarations: [...components, ...pipes, ...directives],
  exports: [
    ...modules,
    ...components,
    ...pipes,
    ...standaloneComponents,
    ...directives
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
