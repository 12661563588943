<div class="grid">
  <div class="col-12">
    <div class="flex flex-column sm:flex-row align-items-center gap-4">
      <div class="flex flex-column sm:flex-row align-items-center">
        <p-button
          icon="pi pi-chevron-left"
          styleClass="md:mr-3"
          [text]="true"
          [plain]="true"
          [routerLink]="['/customers', customerId]"></p-button>
        @if (contractDetails) {
          <span class="font-medium text-3xl text-900 mr-4 block">
            {{ contractDetails.contractNumber + ' ' }}
          </span>
          <p-chip
            label="{{
              'Contracts.StatusDescriptions.' + contractDetails.status
                | translate
            }}"
            [styleClass]="
              'contract-chip status-' + contractDetails.status | lowercase
            "></p-chip>
        }
      </div>
      <div class="flex gap-2 sm:ml-auto">
        @if (showCancelBtn) {
          <p-button
            icon="pi pi-times"
            class="p-button-rounded p-button-outlined p-button p-component p-button-icon-only p-button-danger p-buttonset"
            [text]="true"
            pTooltip="{{ 'Contracts.Cancel' | translate }}"
            tooltipPosition="left"
            (click)="requestCancelContract(this.contractId, $event)"></p-button>
        }
        @if (showSendBalanceEmailBtn) {
          <p-button
            icon="pi pi-envelope"
            class="p-button-rounded p-button-outlined p-button p-component p-button-icon-only"
            [text]="true"
            pTooltip="{{ 'Contracts.SendEmail' | translate }}"
            tooltipPosition="left"
            (onClick)="onSendContractBalance()"></p-button>
        }

        <p-button
          icon="pi pi-download"
          class="p-button-rounded p-button-outlined p-button p-component p-button-icon-only"
          [text]="true"
          pTooltip="{{ 'Contracts.DownloadDraft' | translate }}"
          tooltipPosition="left"
          (onClick)="onDownloadDraft()"></p-button>
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-9 pr-0">
    <div class="card mb-3">
      @if (contractDetails) {
        <app-edit-contract-form
          [contractId]="contractId"
          [contractDetails]="contractDetails">
        </app-edit-contract-form>
      }
    </div>
    <div class="card mb-3">
      <div class="flex flex-column sm:flex-row align-items-center gap-4 mb-3">
        <div class="flex flex-column sm:flex-row align-items-center gap-3">
          <div class="text-900 text-xl font-semibold">
            {{ 'Customers.Transactions' | translate }}
          </div>
        </div>
        <div class="flex gap-3 sm:ml-auto">
          <app-amn-dropdown
            [options]="transactionTypes"
            [placeholder]="
              translateService.instant('Transactions.TransactionType')
            "
            field="transactionTypes.label"
            (filterApplied)="filterCallback($event)"
            [descriptionPrefix]="'Transactions.Types.'">
          </app-amn-dropdown>
          <app-add-deposit-contract
            (addDeposit)="onAddDeposit()"></app-add-deposit-contract>
        </div>
      </div>
      <div class="flex flex-column row-gap-3">
        <app-transactions
          [transactions]="selectedStatus ? filteredTransactions : transactions"
          (uploadDocument)="onAddDeposit()"
          (requestDownloadDocument)="
            downloadDocumentService($event.documentId, $event.filename)
          ">
        </app-transactions>
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-3">
    <div class="card mb-3">
      <app-contract-document-timeline [contractDetails]="contractDetails">
      </app-contract-document-timeline>
    </div>
    <div class="card mb-3">
      @if (contractDetails) {
        <app-documents-list
          [documents]="contractDetails.documents"
          (deleteDocumentRequest)="deleteDocumentService($event)"
          (requestDownloadDocument)="
            downloadDocumentService(
              $event.documentId,
              $event.filename || undefined
            )
          ">
        </app-documents-list>
      }
    </div>
    <div class="card mb-3">
      @if (contractId) {
        <app-contract-document-upload
          [contractId]="contractId"
          (documentsUploaded)="onDocumentsUploaded()">
        </app-contract-document-upload>
      }
    </div>
  </div>
</div>
