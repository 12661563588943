/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Currency } from '@app/data/amn-api/models';
import { AlertsService } from '@app/core/services';
import { UserService } from '@app/data/amn-api/services';
import { first, Subscription } from 'rxjs';
import { transformDateToISO } from '@app/core/helpers/date.helper';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '@app/core/services/error.service';
interface TimeDate {
  time: string;
  months: number;
}
@Component({
  standalone: true,
  selector: 'app-add-contract-form',
  imports: [PrimeNgModule, SharedModule],
  templateUrl: './add-contract-form.component.html',
  styleUrls: ['./add-contract-form.component.scss']
})
export class AddContractFormComponent implements OnInit {
  @Input() customerId: number = 0;
  @Input() contractDetails: any;

  @Output() cancelContract = new EventEmitter<void>();
  @Output() submitContract = new EventEmitter<void>();
  @Output() statusChange = new EventEmitter<string>();

  interestOptions!: SelectItem[];
  companyOptions!: SelectItem[];
  userId: number | null = null;
  currencyOptions!: SelectItem[];

  timeDateOptions!: TimeDate[];

  ObjectId?: string = '';
  submitLabel: string = 'Common.Add';

  contractForm!: FormGroup;

  customerSubscription: Subscription = new Subscription();

  showReinvestSwitch: boolean = false;
  canEditReinvestSwitch: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private customerService: UserService,
    private alertsService: AlertsService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.userId = this.customerId;

    this.timeDateOptions = [
      { time: '6 months', months: 6 },
      { time: '1 year', months: 12 },
      { time: '2 years', months: 24 },
      { time: '3 years', months: 36 },
      { time: 'undefined', months: 1200 }
    ];

    this.interestOptions = [
      { label: 'SIMPLE', value: 'SIMPLE' },
      { label: 'COMPOUND', value: 'COMPOUND' }
    ];

    this.companyOptions = [
      { label: 'INVESTMENTS', value: 'INVESTMENTS' },
      { label: 'CAPITAL', value: 'CAPITAL' }
    ];

    this.currencyOptions = [
      { label: 'MXN', value: Currency.Mxn },
      { label: 'USD', value: Currency.Usd }
    ];

    this.contractForm = this.fb.group({
      contractNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(AMN - [A-Z0-9]{3}[ ]{0,1}([A-Z0-9]|_){0,1})$/)
        ]
      ],
      amount: [0, [Validators.required, Validators.min(3000)]],
      currency: [''],
      signDate: null,
      startDate: [null, Validators.required],
      selectedDuration: ['', Validators.required],
      endDate: null,
      status: [''],
      interestType: ['', Validators.required],
      interestRate: [
        0,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ],
      company: ['', Validators.required],
      investmentAmount: [0]
    });

    this.contractForm.get('selectedDuration')?.valueChanges.subscribe(() => {
      this.updateEndDate();
    });

    this.contractForm.get('startDate')?.valueChanges.subscribe(() => {
      this.updateEndDate();
    });

    const idParamCustomer = this.route.snapshot.paramMap.get('customerId');
    this.userId = idParamCustomer ? +idParamCustomer : 0;
    this.contractForm.get('investmentAmount')?.disable();
  }

  emitStatusChange() {
    this.statusChange.emit(this.contractForm.value.status);
  }

  closeDialog(): void {
    this.cancelContract.emit();
  }

  updateEndDate() {
    const selectedDuration = this.contractForm.get('selectedDuration')?.value;
    const startDateValue = this.contractForm.get('startDate')?.value;

    if (selectedDuration && startDateValue) {
      const durationEntry = this.timeDateOptions.find(
        (entry) => entry.months === Number(selectedDuration)
      );
      if (durationEntry) {
        const startDate = new Date(startDateValue);
        const endDate = new Date(startDate.getTime());
        endDate.setMonth(endDate.getMonth() + durationEntry.months);
        endDate.setDate(endDate.getDate() - 1); // Set endDate to the last day of the month (if the month has 31 days, 30 days, 28 days, etc.
        this.contractForm
          .get('endDate')
          ?.setValue(endDate.toISOString().split('T')[0]); // Set endDate in the format 'YYYY-MM-DD'.
      }
    }
  }

  onCancelClick() {
    this.cleanForm();
    this.cancelContract.emit();
  }

  public cleanForm() {
    this.contractForm.reset({
      interestType: ''
    });
  }

  hasError(formControl: string, validationName: string): boolean | undefined {
    return (
      this.contractForm.get(formControl)?.hasError(validationName) &&
      this.contractForm.get(formControl)?.touched
    );
  }

  onSubmit() {
    if (this.contractForm?.valid) {
      this.contractForm.updateValueAndValidity();
      const interestRateDecimal = this.contractForm.get('interestRate')?.value
        ? this.contractForm.get('interestRate')?.value / 100
        : 0;
      const contractData = {
        ...this.contractForm.value,
        contractNumber: this.contractForm.get('contractNumber')?.value,
        userId: this.userId,
        startDate: transformDateToISO(
          this.contractForm.get('startDate')?.value
        ),
        endDate: transformDateToISO(this.contractForm.get('endDate')?.value),
        amount: Number(this.contractForm.get('amount')?.value),
        interestRate: Number(interestRateDecimal),
        status: this.contractForm.get('status')?.value
          ? this.contractForm.get('status')?.value
          : 'NEW',
        company: this.contractForm.get('company')?.value
      };

      if (this.userId) {
        this.customerService
          .createUserContractAsync({ id: this.userId, body: contractData })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.ContractAdded',
                'Alerts.ContractAddedCorrectly',
                true
              );
              this.submitContract.emit();
              this.contractForm.enable();
            },
            error: (error: unknown) => {
              this.errorService.handleError(error, {
                badRequest: 'Alerts.ErrorCreatingContract'
              });
            }
          });
      }
    }
  }
}
