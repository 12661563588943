/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccessService } from './services/access.service';
import { ContractService } from './services/contract.service';
import { CustomerService } from './services/customer.service';
import { CustomerBeneficiariesService } from './services/customer-beneficiaries.service';
import { DocumentService } from './services/document.service';
import { ExpenseService } from './services/expense.service';
import { FinancialAdvisorService } from './services/financial-advisor.service';
import { HealthCheckService } from './services/health-check.service';
import { MyFxBookService } from './services/my-fx-book.service';
import { RatesService } from './services/rates.service';
import { ReportService } from './services/report.service';
import { SettingsService } from './services/settings.service';
import { SimulateService } from './services/simulate.service';
import { TaxRegimeService } from './services/tax-regime.service';
import { UserService } from './services/user.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccessService,
    ContractService,
    CustomerService,
    CustomerBeneficiariesService,
    DocumentService,
    ExpenseService,
    FinancialAdvisorService,
    HealthCheckService,
    MyFxBookService,
    RatesService,
    ReportService,
    SettingsService,
    SimulateService,
    TaxRegimeService,
    UserService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
