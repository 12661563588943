<form (ngSubmit)="onSubmit()" #customerForm="ngForm" [formGroup]="contractForm">
  <div class="grid formgrid p-fluid">
    <div class="field col-12 md:col-6 xl:col-3">
      <app-amn-input-mask
        id="contractNumber"
        label="{{ 'Contracts.ContractNumber' | translate }}"
        mask="AMN - ***? *"
        formControlName="contractNumber"
        name="contractNumber"
        [errorMessages]="{
          required: 'Contract number is required',
          pattern:
            'Invalid format. Use 3 uppercased letters/numbers optionally followed by 1 uppercased letter/number.'
        }"
        placeholder="AMN - ***? *">
      </app-amn-input-mask>
    </div>
    <div class="field col-12 md:col-6 xl:col-3">
      <app-amn-input-money
        id="OriginalContractAmount"
        label="{{ 'Contracts.OriginalContractAmount' | translate }}"
        formControlName="amount"
        [errorMessages]="{
          required: 'Amount is required.',
          min: 'the amount must be great than 3000'
        }">
      </app-amn-input-money>
    </div>
    <div class="field col-12 md:col-6 xl:col-2">
      <label for="currency" class="font-medium text-900">{{
        'Banks.Currency' | translate
      }}</label>
      <p-dropdown
        id="currency"
        [options]="currencyOptions"
        optionValue="value"
        optionLabel="label"
        formControlName="currency"
        placeholder="Select currency"
        appendTo="body">
      </p-dropdown>
    </div>
    <div class="field col-6 md:col-6 xl:col-4">
      <app-amn-input-date
        id="initialDate"
        label="{{ 'Contracts.InitialDate' | translate }}"
        formControlName="startDate"
        view="month"
        [errorMessages]="{ required: 'Initial date is required' }">
      </app-amn-input-date>
    </div>
    <div class="field col-6 md:col-6 xl:col-3">
      <label for="timeDate" class="font-medium text-900">{{
        'Contracts.FinalDate' | translate
      }}</label>
      <p-dropdown
        id="timeDate"
        [options]="timeDateOptions"
        optionValue="months"
        optionLabel="time"
        formControlName="selectedDuration"
        appendTo="body"
        placeholder="Select the time lapse">
      </p-dropdown>
    </div>
    <div class="field col-6 md:col-6 xl:col-3">
      <label for="interestType" class="font-medium text-900">{{
        'Contracts.TypeOfInterest' | translate
      }}</label>
      <p-dropdown
        id="interestType"
        [options]="interestOptions"
        optionValue="value"
        optionLabel="label"
        formControlName="interestType"
        placeholder="Select Interest Type"
        appendTo="body">
      </p-dropdown>
    </div>
    <div class="field col-6 md:col-6 xl:col-3">
      <app-amn-input-percent
        id="percentage"
        prefix="%"
        label="{{ 'Contracts.Percentage' | translate }}"
        formControlName="interestRate"
        [errorMessages]="{
          required: 'Percentage is required.',
          max: 'Max 100',
          min: 'Min 0'
        }">
      </app-amn-input-percent>
    </div>
    <div class="field col-6 md:col-6 xl:col-3">
      <label for="company" class="font-medium text-900">{{
        'Contracts.Company' | translate
      }}</label>
      <p-dropdown
        id="company"
        [options]="companyOptions"
        optionValue="value"
        optionLabel="label"
        formControlName="company"
        placeholder="Select Company"
        appendTo="body">
      </p-dropdown>
    </div>
    <div class="w-full flex justify-content-end flex-wrap">
      <p-button
        label="{{ 'Common.Cancel' | translate }}"
        icon="pi pi-times"
        class="w-auto mr-3 mt-3"
        [outlined]="true"
        severity="secondary"
        (click)="onCancelClick()"></p-button>
      <p-button
        type="submit"
        label="{{ 'Common.Add' | translate }}"
        icon="pi pi-check"
        class="w-auto mt-3 mr-3"
        [outlined]="true"
        severity="primary"
        [disabled]="!this.contractForm.valid"></p-button>
    </div>
  </div>
</form>
