import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { AddContractFormComponent } from '../add-contract-form/add-contract-form.component';

@Component({
  standalone: true,
  selector: 'app-add-contract',
  imports: [PrimeNgModule, AddContractFormComponent, TranslateModule],
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss']
})
export class AddContractComponent {
  visible: boolean = false;

  @Output() cancelContract = new EventEmitter<void>();
  @Output() addContract = new EventEmitter<void>();

  @ViewChild(AddContractFormComponent)
  addContractFormComponent!: AddContractFormComponent;

  onAddClick(): void {
    this.visible = true;
    this.addContractFormComponent.cleanForm();
  }

  onCancelClick(): void {
    this.visible = false;
    this.cancelContract.emit();
  }

  onSubmit(): void {
    this.visible = false;
    this.addContract.emit();
  }
}
