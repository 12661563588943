import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { UserService } from '@app/data/amn-api/services';
import { AlertsService } from '@app/core/services/alerts.service';
import { first } from 'rxjs';
import { CreateUserAsync$Params } from '@app/data/amn-api/fn/user/create-user-async';
import { RoleDto, UserDto } from '@app/data/amn-api/models';
import { EditUserAsync$Params } from '@app/data/amn-api/fn/user/edit-user-async';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';

interface IUserRoles {
  name: string;
  code: string;
}

@Component({
  selector: 'app-add-user-form',
  standalone: true,
  imports: [PrimeNgModule, SharedModule, ToggleButtonModule],
  templateUrl: './add-user-form.component.html',
  styleUrl: './add-user-form.component.scss'
})
export class AddUserFormComponent implements OnInit, OnChanges {
  @Input() userDetails: UserDto | null = null;
  @Input() isToEdit = false;
  @Input() random!: number;
  @Output() showParentModal = new EventEmitter<boolean>();
  @Output() addNewUser = new EventEmitter<void>();

  userForm!: FormGroup;
  rolesArray!: IUserRoles[];
  hasB2CRecord = false;

  private userService = inject(UserService);
  private fb = inject(FormBuilder);
  private alertsService = inject(AlertsService);

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (!!changes['userDetails'] && !!changes['userDetails'].currentValue) ||
      changes['random']
    ) {
      if (this.userDetails) {
        this.hasB2CRecord = false;
        let isEmailDisable = false;
        this.userService
          .getUserAsync$Response({ id: this.userDetails.id ?? 0 })
          .pipe(first())
          .subscribe({
            next: (response) => {
              isEmailDisable = response.body.data?.objectId ? true : false;
              if (isEmailDisable) {
                this.userForm.get('email')?.disable();
                this.hasB2CRecord = isEmailDisable;
              }
            }
          });

        const roles: IUserRoles[] = [];
        if (this.userDetails.roles?.length) {
          this.userDetails.roles.forEach((role: RoleDto) => {
            if (role.id === 1) {
              roles.push({ name: 'Master', code: '1' });
            }
            if (role.id === 2) {
              roles.push({ name: 'Admin', code: '2' });
            }
            if (role.id === 3) {
              roles.push({ name: 'User', code: '3' });
            }
          });
        }

        this.userForm = this.fb.group({
          name: new FormControl<string>(this.userDetails.name ?? '', [
            Validators.required,
            Validators.maxLength(100)
          ]),
          lastName: new FormControl<string>(this.userDetails.lastName ?? '', [
            Validators.required,
            Validators.maxLength(100)
          ]),
          email: new FormControl<string>(this.userDetails.email ?? '', [
            Validators.required,
            Validators.email,
            Validators.maxLength(50)
          ]),
          selectedRoles: new FormControl<IUserRoles[] | null>(
            roles.length ? roles : null,
            [Validators.required]
          ),
          isActive: new FormControl(this.userDetails.isActive, [
            Validators.required
          ])
        });
      }
    }
  }

  ngOnInit(): void {
    this.rolesArray = [
      { name: 'Admin', code: '2' },
      { name: 'User', code: '3' },
      { name: 'Financial Advisor', code: '4' }
    ];
    this.userForm = this.fb.group({
      name: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      lastName: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ]),
      selectedRoles: new FormControl<IUserRoles[] | null>(null, [
        Validators.required
      ]),
      isActive: new FormControl<boolean>(true, [Validators.required])
    });
  }

  onSubmit(): void {
    const roles: number[] = [];
    this.userForm.value.selectedRoles
      .map((m: IUserRoles) => m.code)
      .forEach((item: number) => {
        roles.push(item);
      });

    if (!this.isToEdit && !this.userDetails) {
      const payload: CreateUserAsync$Params = {
        body: {
          name: this.userForm.value.name,
          lastName: this.userForm.value.lastName,
          email: this.userForm.value.email,
          roles,
          isActive: this.userForm.value.isActive
        }
      };

      this.userService
        .createUserAsync(payload)
        .pipe(first())
        .subscribe({
          next: (response) => {
            if (response.status === 201 || response.status === 200) {
              this.onCancelClick();
              this.alertsService.addSuccess(
                'Alerts.UserAdded',
                'Alerts.UserAddedCorrectly',
                true
              );
              this.addNewUser.emit();
            }
          }
        });
    } else {
      const payload: EditUserAsync$Params = {
        id: this.userDetails?.id ?? 0,
        body: {
          name: this.userForm.value.name,
          lastName: this.userForm.value.lastName,
          email: this.userForm.value.email,
          roles,
          isActive: this.userForm.value.isActive
        }
      };

      this.userService
        .editUserAsync(payload)
        .pipe(first())
        .subscribe({
          next: (response) => {
            if (response.status === 201 || response.status === 200) {
              this.onCancelClick();
              this.alertsService.addSuccess(
                'Alerts.UserEdited',
                'Alerts.UserEditedCorrectly',
                true
              );
              this.addNewUser.emit();
            }
          }
        });
    }
  }

  onIsActiveChange($event: InputSwitchChangeEvent) {
    this.userForm.get('isActive')?.setValue($event.checked);
  }

  onAddClick(): void {
    this.userForm.reset();
  }

  onCancelClick(): void {
    this.userForm.reset();
    this.showParentModal.emit(false);
  }
}
